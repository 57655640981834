import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import BasicPageCard from '@/shared/components/layouts/BasicPageCard'

Vue.component('partner-admin-page-card', BasicPageCard)

const PartnerAdmin = {
  name: 'PartnerAdmin',
  render(h) {
    if (store.getters['Ability/managePartnerAdminBoard']) {
      document.title = 'FI Navigator'
      return h('router-view')
    } else {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  }
}

export default PartnerAdmin
